// Library Imports
import React from 'react';
// import ReactDOM from 'react-dom';
// import axios from 'axios';
import { CSSTransitionGroup } from 'react-transition-group';

// Module imports
import Layout from '../components/layout.js';
import ColorBar from '../components/ColorBar.jsx';
import ChartGroup from '../components/Charts.jsx';
import ResultsPage from '../components/Results.jsx';
import TestTooltip from '../components/Tooltip.jsx';
// import { DatabaseContent } from '../components/Database.jsx';
import { USAMap, DrilldownMap } from '../components/Maps.jsx';

// import db from '../common/fire.js';
import colorizers from '../common/colorize.js';
import { clamp, formatPercent } from '../common/util.js';
import { efficiencyGap, MIN_DISTS } from '../common/tests.js';

// CSS imports
import './tests.css';
// import './style.css';
import '../styles/styles.css';

// json imports
import YEAR_TO_FILE from '../data/year_to_file.json';
import STATE_ABBREV_TO_NAME from '../data/state_abbrev_to_name.json';
import RESULTS_TESTS_CONGRESSIONAL from '../data/results_and_tests/congressional.json';
import RESULTS_TESTS_STATE from '../data/results_and_tests/state.json';

// Min and max years for election data
const YEARS_CONGRESS = [1948, 2018];
const YEARS_STATES = [2012, 2018];

// Map title prefix before year
const TITLE_CONGRESS = 'U.S. Congressional Races: ';
const TITLE_STATES = 'State Legislative Races: ';

// Load Notes and special case tooltips from the database
let TOOLTIPS = {}, NOTES = {};
// db.ref('/tests/notes').once('value').then((snapshot) => {
//   NOTES = snapshot.val();
// });

// db.ref('/tests/tooltips').once('value').then((snapshot) => {
//   TOOLTIPS = snapshot.val();
// });

/**
 * @classdesc
 * This is the Data Controller for the underlying map components
 * which renders the imported USAMap or DrilldownMap components
 * depending on its state.  The choropleth coloring is determined
 * by the colorize function passed to the child map, and can be
 * toggled by buttons in this component.
 */
export default class GerryMap extends React.Component {
  constructor(props) {
    super(props);

    // Bind instance methods
    this.drilldown        = this.drilldown.bind(this);
    this.drillup          = this.drillup.bind(this);
    this.toggleCongress   = this.toggleCongress.bind(this);
    this.toggleStates     = this.toggleStates.bind(this);
    this.tooltipMain      = this.tooltipMain.bind(this);
    this.tooltipDrilldown = this.tooltipDrilldown.bind(this);
    this.parseHash        = this.parseHash.bind(this);

    // Initialize data
    this.shapefileFor = YEAR_TO_FILE;
    this.statenames = STATE_ABBREV_TO_NAME;
    this.allResultsCongress = RESULTS_TESTS_CONGRESSIONAL;
    this.allResultsStates = RESULTS_TESTS_STATE;

    // State information for the congress
    this.configCongress = {
      isCongress: true,
      title: TITLE_CONGRESS,
      minYear: YEARS_CONGRESS[0],
      maxYear: YEARS_CONGRESS[1],
    };

    // State information for state legislatures
    this.configStates = {
      isCongress: false,
      title: TITLE_STATES,
      minYear: YEARS_STATES[0],
      maxYear: YEARS_STATES[1],
    }

    // Initial state
    this.state = Object.assign({
      drilldown: null,
      year: 2018,
      colorIdx: 0,
      data: this.allResultsCongress,
    }, this.configCongress);

    // Bind the object to the colorize options
    this.colorizers = colorizers.call(this);
  }

  /**
   * Reset to default props on error
   */
  componentDidCatch(e) {
    this.setState({drilldown: null});
  }

  /**
   * Asynchronously load the remainder of the data on component mount
   */
  componentDidMount() {
    this.parseHash();
    window.addEventListener('hashchange', this.parseHash);
  }

  parseHash() {
    // Routing with the URL hash
    let [which, year, drilldown] = window.location.hash.replace(/^#\/?|\/$/g, '').split('/');

    if (drilldown) {
      const {data} = this.state;
      drilldown = drilldown.toUpperCase();
      if (data[year] && data[year][drilldown] && data[year][drilldown].ndists >= MIN_DISTS) {
        this.setState({drilldown})
      }
    }

    if (year) {
      if (year % 2 === 0) {
        this.setState({year: parseInt(year)});
      } else {
        window.location.hash = '';
        return;
      }
    }

    if (which === 'states') {
      this.toggleStates();
    } else if (which === 'congress') {
      this.toggleCongress();
    } else {
      window.location.hash = '';
    }
  }

  /**
   * Simple transition into the drilled down state.  Take
   * new drilldown from the geography referenced in the click event
   */
  drilldown(geo) {
    const {data, year} = this.state;
    if (data[year][geo.properties.id] &&
        data[year][geo.properties.id].ndists >= MIN_DISTS &&
        !data[year][geo.properties.id].multimember)
      this.setState({drilldown: geo.properties.id, colorIdx: 0});
  }

  /**
   * Drill up by simply nullifying this.state.drilldown
   */
  drillup() {
    this.setState({drilldown: null, colorIdx: 0});
    window.location.hash = '';
  }

  /**
   * Tooltip to show per district when the map is full size
   * @param {String} id -- the two-letter state abbreviation
   *                       for the currently hovered state
   */
  tooltipMain(id) {
    if (id == null) return null;

    const {year, data} = this.state;
    const results = data[year][id];

    // if (TOOLTIPS[id] && TOOLTIPS[id][year])
      // return <div dangerouslySetInnerHTML={{__html: TOOLTIPS[id][year]}}/>

    const name = this.statenames[id];

    return <TestTooltip results={results} name={name} year={year} />
  }

  /**
   * Tooltip to show per district when the map is drilled down
   * @param {Number} idx -- the district number
   */
  tooltipDrilldown(idx) {
    if (!this.state.isCongress) return null;
    const {year, data, drilldown} = this.state;
    const {results} = data[year][drilldown];
    return (results[idx] >= 0.5)
      ? <span>District {idx + 1}: <strong>{formatPercent(results[idx])} Democratic</strong></span>
      : <span>District {idx + 1}: <strong>{formatPercent(1 - results[idx])} Republican</strong></span>
  }

  /**
   * Switch to displaying U.S. Congressional data
   */
  toggleCongress() {
    const {year} = this.state;
    this.setState(this.configCongress);
    this.setState({
      data: this.allResultsCongress,
      year: clamp(year, ...YEARS_CONGRESS),
      colorIdx: 0
    });
  }

  /**
   * Switch to displaying State Legislative data
   */
  toggleStates() {
    const {year} = this.state;
    this.setState(this.configStates);
    this.setState({
      data: this.allResultsStates,
      year: clamp(year, ...YEARS_STATES),
      colorIdx: 0
    });
  }

  render() {
    const {drilldown, colorIdx, year, minYear, maxYear, title, isCongress, data} = this.state;

    // Which colorize options are available, and how to color the map
    const availableColorizers = this.colorizers[isCongress ? 'congress' : 'states'][drilldown ? 'down' : 'up'];
    const {colorize, colors, labels, desc, discrete} = availableColorizers[colorIdx];

    // Hack the display year for state legislative data
    const displayYear = (isCongress) ? year : `${year-1}\u2013${year}`;

    // Get the state results if we're drilled down
    let {voteshare, dseats, ndists} = drilldown ? data[year][drilldown] : {};
    let rseats = ndists - dseats;
    let eg = drilldown && efficiencyGap(dseats, ndists, voteshare);

    // Figure out our screen size
    let isMobile;
    if (typeof window !== `undefined`) {
      isMobile = window.matchMedia('(max-width: 550px)').matches;
    }

    // Build the note
    let note = drilldown
      && NOTES[drilldown]
      && NOTES[drilldown][year];

    // Figure out the drilldown file
    let file = drilldown
      && isCongress
      ? this.shapefileFor[drilldown][year]
      : `${drilldown}-empty.topojson`;

    return (
      <Layout>
      <div>
      <div id="map-wrapper" className="container2">
      <div id='title-and-state'>
        <div id='map-title'>
          <div id="map-toggles">
            <div id="map-data-toggle" className="button-wrapper">
              <button className={`button-data-toggle${isCongress ? ' selected' : ''}`}
                onClick={this.toggleCongress} disabled={(drilldown && (!this.allResultsCongress.hasOwnProperty(year) || !this.allResultsCongress[year].hasOwnProperty(drilldown) || (this.allResultsCongress[year][drilldown].ndists < MIN_DISTS)))}>
                  U.S. Congress
                  </button>
              <button className={`button-data-toggle${isCongress ? '' : ' selected'}`}
                onClick={this.toggleStates} disabled={(drilldown && (!this.allResultsStates.hasOwnProperty(year) || !this.allResultsStates[year].hasOwnProperty(drilldown) || this.allResultsStates[year][drilldown].multimember)) || (year<=2010)}>State Legislatures</button>this.allResultsCongress[year]
            </div>
          </div>
          <div id="year-select">
            <button className="button-year button-year-left" disabled={year <= minYear}
              onClick={() => year > minYear && this.setState(({year}) => ({year: year - 2}))}>{'<'}</button>
            <span className='year-text'>{displayYear}</span>
            <button className="button-year button-year-right" disabled={(year >= maxYear) || (drilldown && (!data[year+2] || !data[year+2].hasOwnProperty(drilldown)))}
              onClick={() => year < maxYear && this.setState(({year}) => ({year: year + 2}))}>{'>'}</button>
          </div>
        </div>
        {drilldown ? <h3>{this.statenames[drilldown]}</h3> : ''}
        </div>

        {drilldown &&
          <React.Fragment>
            <ShareLink race={isCongress ? 'congress' : 'states'} year={year} state={drilldown}/>
            {/*{note && <div id="note" dangerouslySetInnerHTML={{__html: note}}/>}*/}
          </React.Fragment>
        }
        {!isCongress && drilldown && <div id="no-shapes-apology" className="footnote">District shapes are unavailable for state legislatures.</div>}

        <CSSTransitionGroup id="map-charts"
          component="div"
          transitionName="chart-slide"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}>
        {drilldown && !isMobile && (
          <ChartGroup key="chartgroup" {...this.state.data[year][drilldown]}/>
        )}
        </CSSTransitionGroup>

        <div id={`map-colorbar${drilldown ? '-drilldown' : ''}`} style={{textAlign: 'center'}}>
          <div className="colorbar-desc">{desc}</div>
          <ColorBar colors={colors} labels={labels} desc={desc} discrete={discrete}/>
          <div className="button-bar">
          {availableColorizers.length > 1 && availableColorizers.map(({name}, idx) =>
            <button key={name}
              className={`button-toggle${(idx === colorIdx) ? ' selected' : ''}`}
              onClick={() => this.setState({colorIdx: idx})}>
              {name}
            </button>
          )}
          </div>
        </div>
        <CSSTransitionGroup component="div"
          id={(drilldown) ? 'drilldown-map' : 'usa-map'}
          transitionName="map-fade"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}>
          {(drilldown) &&
            <div id="results-area">
              <div>D: <strong>{formatPercent(voteshare)}</strong> ({dseats} seat{dseats === 1 ? '' : 's'})</div>
              <div>R: <strong>{formatPercent(1 - voteshare)}</strong> ({rseats} seat{rseats === 1 ? '' : 's'})</div>
              <div>Efficiency Gap: <strong>{formatPercent(Math.abs(eg)) + (eg > 0 ? ' D' : ' R')}</strong></div>
            </div>
          }
          {(drilldown) ? (
            <DrilldownMap key="drilldown"
              drillup={this.drillup}
              tooltipFor={this.tooltipDrilldown}
              colorize={colorize}
              file={file} />
          ) : (
            <USAMap key="drillup"
              colorize={colorize}
              tooltipFor={this.tooltipMain}
              onClick={this.drilldown} />
          )}
        </CSSTransitionGroup>
    </div>
    <div className="container footnote" style={{ marginTop: '100px' }}>
    Wondering how the tests work? Check out our <a href="/info">explainer</a> page.
    </div>
      {(drilldown) &&
        <React.Fragment>
          <ResultsPage {...data[year][drilldown]} statename={this.statenames[drilldown]} includeCharts={isMobile}/>
        </React.Fragment>
      }
    </div>
    </Layout>
    );
  }
}

class ShareLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }

  componentWillReceiveProps() {
    this.setState({show: false});
  }

  render() {
    const {race, state, year} = this.props;

    return (
      <div id="share-button">
        {this.state.show ? (
          <input id="share-link" value={`http://gerrymander.princeton.edu/tests/#/${race}/${year}/${state}`} />
        ) : (
          <span id="share-nolink"
            onClick={() => this.setState({show: true})}>
            <i className="fas fa-share-square"></i>Share
          </span>
        )}
      </div>
    );
  }
}