/**
 * tests.js
 * Javascript implementations of the
 */

// P-value threshold for failed test
export const THRESHOLD = 0.05;
// Minimum number of simulation matches for valid monte-carlo
export const MIN_MATCHES = 100;
// Minimum number of districts to run tests
export const MIN_DISTS = 6;
// Minimum number of seats for each party to run T-Test
export const MIN_SEATS = 2;
// Minimum voteshare for each state to run mean-median
export const MIN_VOTESHARE = 0.45;

/**
 * A function to compute the Efficiency Gap for a particular election
 * in which a party won seats/dists of the districts, and won voteshare%
 * of the two-party total.
 * @param {Number} seats -- the integer number of D seats won
 * @param {Number} dists -- the integer number of districts in the state
 * @param {Number} voteshare --
 */
export function efficiencyGap(seats, dists, voteshare) {
    return (seats / dists - 0.5) - 2*(voteshare - 0.5);
}

export function validateTTest(dseats, rseats) {
	return (dseats >= 2) && (rseats >= 2);
}

export function validateMeanMedian({mean}) {
	return mean >= MIN_VOTESHARE && (1 - mean) >= MIN_VOTESHARE;
}

export function validateMonteCarlo({n_matches}) {
	return n_matches > MIN_MATCHES;
}
