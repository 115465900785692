import React from 'react';

import {TTestChart, MeanMedianChart, MonteCarloChart} from './Charts.jsx';
import {TTestText, MeanMedianText, MonteCarloText} from './ResultsText.jsx';

// Alpha level for significance
import {THRESHOLD, validateTTest, validateMeanMedian, validateMonteCarlo} from '../common/tests.js';


import './results.css';

// Kinda weird way of keeping track of test parameters
const DEFAULT_PARAMS = [
	{
		name: 'Number of simulations',
		val: 1000000,
	},{
		name: 'Omitted states',
		val: 'MI, NC, OH, PA, VA, WI'
	},{
		name: 'Uncontested imputation value',
		val: 75
	},{
		name: 'Ignore voter clustering',
		val: false
	}
];

/**
 *
 */
export default class ResultsPage extends React.Component {
	render() {
		let {test1, test2, test3, statename, results, year, voteshare, ndists, seats, includeCharts, style} = this.props;
		// let params = DEFAULT_PARAMS;

		return (
			<div id="results" style={style}>
				<div className="results-bar container">
					<ResultsTitle year={year} statename={statename} />
					<div id="passfails">
						<TestResult valid={validateTTest(seats, ndists - seats)} p={test1.p} index="1" name="Lopsided Wins" />
						<TestResult valid={validateMeanMedian(test2)} p={test2.p} index="2" name="Consistent Advantage" />
						{test3 && <TestResult valid={validateMonteCarlo(test3)} p={test3.p} index="3" name="Simulated Elections" />}
					</div>
				</div>
				<div id="results-text">
					<ResultsText id="results-1" title="Lopsided Wins (t-test)">
						<TTestText {...test1}
							seats={seats}
							ndists={ndists}
							statename={statename}
							year={year} />
					</ResultsText>
					{includeCharts ? <TTestChart {...test1} results={results} /> : null}
					<ResultsText id="results-2" title="Consistent Advantage (mean-median difference)">
						<MeanMedianText {...test2}
							statename={statename}
							voteshare={voteshare}
							year={year} />
					</ResultsText>
					{includeCharts ? <MeanMedianChart {...test2} results={results} /> : null}
					{test3 && <ResultsText id="results-3" title="Simulated Elections (Monte Carlo)">
						<MonteCarloText {...test3}
							statename={statename}
							voteshare={voteshare}
							seats={seats}
							ndists={ndists}
							year={year} />
					</ResultsText>
					}
					{includeCharts && test3 ? <MonteCarloChart {...test3} /> : null}
					<ExtraInfo />
					{/*<TestParams params={params}/>*/}
				</div>
			</div>
		);
	}
}

/**
 *
 */
const ResultsTitle = ({year, statename}) => {
	return (
		<div className="title-bar">
			<span className="title-bar-text">
			  Detailed Results
				{/*<span className="state"><b>{statename} </b></span>
				<span className="year"><b>{year}</b></span>*/}
			</span>
		</div>
	);
};

/**
 * A single box to announce the results of a test
 */
const TestResult = ({valid, p, index, name}) => {
	const result = 	(!valid)
									 ? <h3 className="test-skip">Skipped</h3>
									 : (p < THRESHOLD)
									   ? <h3 className="test-fail">Failed</h3>
									 	 : <h3 className="test-pass">Passed</h3>


	return (
		<div className="test-result">
			<h4 className="test-name">{name}</h4>
			{result}
		</div>
	);
}

const ResultsText = ({id, title, children}) => {
	return (
		<div id={id} className="results-desc">
			<h3 className="test-title">{title}</h3>
			{children}
		</div>
	);
};

const ExtraInfo = () => {
	return (
		<div className="extra-info">
			<p>Samuel S.-H. Wang, "<a href="https://www.stanfordlawreview.org/print/article/three-tests-for-practical-evaluation-of-partisan-gerrymandering/" target="_blank" rel="noopener noreferrer">Three Tests for
			Practical Evaluation of Partisan Gerrymandering</a>," 68 Stan. L. Rev. 1263 (2016).</p>
			<p>Uncontested districts were treated as if the winning candidate received 75% of the two-party vote.</p>
		</div>
	);
};

// const TestParams = ({params}) => {
// 	return (
// 		<div className="params">
// 			<span>Simulation parameters:</span>
// 			{params.map(({name, val}) => {
// 				return (
// 					<span key={name} className="param">
// 						<span className="param-name">{name}</span>:
// 						<span className="param-val">{val}</span>
// 					</span>
// 				);
// 			})}
// 		</div>
// 	);
// };
