import React from 'react';
import './colorbar.css';

/**
 * Display the colors that we're using
 */
const ColorBar = ({colors, desc, labels=[], discrete=false}) =>  {

  // const last = colors.length - 1;

  return (
    <div className="colorbar">
      <div className="colorbar-bar">
      {colors.map((color) =>
        <div key={color} className="colorbar-color"
          style={{backgroundColor: color}} />
      )}
      </div>
      <div className="colorbar-labels">
      {labels.map((label, idx) =>
        <div key={label} className="colorbar-label"
          style={{
            visibility: !discrete && idx === 0 ? 'hidden' : 'visible',
            textAlign: discrete ? 'center' : 'left',
            transform: discrete ? 'none' : 'translateX(-0.5em)'
          }}>
          {label}
        </div>
      )}
      </div>
    </div>
  );
};

export default ColorBar;