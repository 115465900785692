/**
 * colorize.js
 * Functions for calculating the color of a state according 
 * to a particular metric.  All functions use a GerryMap instance
 * as @this, and use the current state to find the relevant data.
 * Functions for colorizing the entire US map take an id as input 
 * in the form of a 2-letter state abbreviation.  Functions for colorizing
 * district-level results take a district index.
 */
import colormap from 'colormap';

import {efficiencyGap, MIN_DISTS, MIN_MATCHES, THRESHOLD, validateTTest, validateMeanMedian, validateMonteCarlo} from './tests.js';

// Test validators in a single array, for iterating through tests
const validate = [
  ({seats, ndists}) => validateTTest(seats, ndists - seats), 
  ({test2}) => validateMeanMedian(test2), 
  ({test3}) => validateMonteCarlo(test3)
];

/** Colormap from blue to red */
const REDBLUE = colormap({colormap: 'RdBu', nshades: 10}).reverse();
/** Colormap for intensity */
const ELECTRIC = colormap({colormap: 'electric', nshades: 15}).reverse().slice(1, 9);

/** Green, yellow, orange, red */
const STOPLIGHT4 = [ "#53e34c", "#efe940", "#ea924d", "#e32d28"];
/** Green, orange, red */
const STOPLIGHT3 = [ "#53e34c", "#ef8700", "#e32d28"];

// The color to use for no data 
const DEFAULT_COLOR = '#e2e2e2';

// Keep the colors named and easily editable
export const Colors = {
  DEM: '#0080FF',
  REP: '#FF3333',
  FAIL: '#dd0000',
  GREY: '#aaa',
  NEUTRAL: '#7cb5ec',
  HIGHLIGHT: '#449911'
};

/**
 * Pick a color from a colormap array, based on a value 
 * between zero and one.  Return a default color for undefined
 * values and clamp those greater or less than zero to the 
 * @param {Number} val -- a number between 0 and 1
 * @param {Array} cmap -- the colormap to use
 */
function pick(val, cmap, nocolor=DEFAULT_COLOR) {
  if (val === undefined) return nocolor;
  return (val <= 0)
    ? cmap[0] : (val >= 1) ? cmap[cmap.length-1]
    : cmap[Math.floor(val * cmap.length)];
};

/**
 * Return an object mapping data state to available map colorizing 
 * functions along with each corresponding array of colors, labels, and
 * description.
 *
 * @this -- a GerryMap component instance
 */
export default function colorizers() {
  /**
   * A red-to-blue colormap based on statewide voteshare.
   */
  const PARTISANSHIP_STATEWIDE = {
    name: 'Vote Share',
    colorize: (id) => {
      let results = this.state.data 
        && this.state.data[this.state.year]
        && this.state.data[this.state.year][id];

      if (!results) return DEFAULT_COLOR;

      return pick(results.voteshare, REDBLUE);
    },
    colors: REDBLUE,
    labels: REDBLUE.map((v,i) => parseInt(100*(i/REDBLUE.length)) + '%'),
    discrete: false,
    desc: 'Democratic vote share'
  };

  /**
   * A red-to-blue colormap for individual district voteshare
   */
  const PARTISANSHIP_DISTRICT = {
    name: 'Vote Share',
    colorize: (idx) => {
      let {year, drilldown} = this.state;
      if (!drilldown) return DEFAULT_COLOR;

      let {results} = this.state.data[year][drilldown];
      return pick(results[idx], REDBLUE);
    },
    colors: REDBLUE,
    labels: REDBLUE.map((v,i) => parseInt(100*(i/REDBLUE.length)) + '%'),
    discrete: false,
    desc: 'Democratic vote share'
  };

  /**
   * A red-to-blue colormap that averages all of the districts, for state legislative drilldown
   */
  const PARTISANSHIP_AVERAGE = {
    name: 'Vote Share',
    colorize: () => {
      let {year, drilldown} = this.state;
      if (!drilldown) return DEFAULT_COLOR;

      let {results} = this.state.data[year][drilldown];


      let total = 0;
      for (const voteshare of results) {
        total += voteshare;
      }

      return pick(total / results.length, REDBLUE);
    },
    colors: REDBLUE,
    labels: REDBLUE.map((v,i) => parseInt(100*(i/REDBLUE.length)) + '%'),
    discrete: false,
    desc: 'Democratic vote share'
  }

  /**
   * A red-to-blue colormap for the fraction of congressional seats won
   */
  const SEATS_WON = {
    name: 'Seat Share',
    colorize: (id) => {
      let results = this.state.data
        && this.state.data[this.state.year]
        && this.state.data[this.state.year][id];

      if (!results || results.multimember) return DEFAULT_COLOR;

      return pick(results.dseats / results.ndists, REDBLUE);
    },
    colors: REDBLUE,
    labels: REDBLUE.map((v,i) => parseInt(100*(i/REDBLUE.length)) + '%'),
    discrete: false,
    desc: 'Democratic seat share'
  };

  /**
   * The classic color scheme.  Simply count the number of failed tests and
   * map the result to green, yellow, orange, red.  If there are not enough
   * districts to run tests, return grey.
   */
  const FAILED_CONGRESS = {
    name: 'Tests Failed',
    colorize: (id) => {
      let results = this.state.data
        && this.state.data[this.state.year]
        && this.state.data[this.state.year][id];

      if (!results || results.ndists < MIN_DISTS)
        return DEFAULT_COLOR;
      
      const valid_pvals = [1,2,3].map((i) => (results[`test${i}`] && validate[i-1](results) && results[`test${i}`].p));
      
      const failed = valid_pvals.reduce((sum, p) => sum + (p && 0 <= p && p < THRESHOLD), 0);
      
      return STOPLIGHT4[failed];
    },
    colors: STOPLIGHT4,
    labels: [0, 1, 2, 3],
    discrete: true,
    desc: 'Number of tests failed'
  };

  /**
   * The classic color scheme modified for state legislative elections.
   * Map the number of failed tests to green, orange, red.  If there are 
   * not enough districts to run tests, return grey.
   */
  const FAILED_STATES = {
    name: 'Tests Failed',
    colorize: (id) => {
      let results = this.state.data
        && this.state.data[this.state.year]
        && this.state.data[this.state.year][id];

      if (!results || results.ndists < MIN_DISTS || results.multimember)
        return DEFAULT_COLOR;

      const valid_pvals = [1,2].map((i) => (results[`test${i}`] && validate[i-1](results) && results[`test${i}`].p));
      const failed = valid_pvals.reduce((sum, p) => sum + (p && 0 <= p && p < THRESHOLD), 0);

      return STOPLIGHT3[failed];
    },
    colors: STOPLIGHT3,
    labels: [0, 1, 2],
    discrete: true,
    desc: 'Number of tests failed'
  };

  /**
   * A red-to-blue colormap for the value of the efficiency gap.
   */
  const EFFICIENCY_GAP = {
    name: 'Efficiency Gap',
    colorize: (id) => {
      let results = this.state.data
        && this.state.data[this.state.year]
        && this.state.data[this.state.year][id];
        
      if (!results) return DEFAULT_COLOR;

      const eg = efficiencyGap(results.dseats, results.ndists, results.voteshare);

      return pick(2 * eg + 0.5, REDBLUE.reverse())
    },
    colors: REDBLUE,
    labels: REDBLUE.map((v,i) => parseInt(100*((i/REDBLUE.length) - 0.5)/2) + '%'),
    discrete: false,
    desc: 'Magnitude of efficiency gap'
  };

  /**
   * A discrete colormap simply indicating the winner of a particular district.
   * Red for republican (voteshare < 0.5), and blue for democrat.  Independents
   * are colored yellow.
   */
  const DISTRICT_WINNER = {
    name: 'District Winner',
    colorize: (idx) => {
      let {year, drilldown} = this.state;
      if (!drilldown) return DEFAULT_COLOR;

      let {results} = this.state.data[year][drilldown];
      return results[idx] >= 0 ? (results[idx] > 0.5 ? '#0080FF' : '#FF3333') : '#CCDD00';
    },
    colors: ['#FF3333', '#0080FF'],
    labels: ['Republican', 'Democratic'],
    discrete: true,
    desc: 'Winning party'
  };

  /**
   * An intensity-based colormap for number of seats gained by gerrymandering
   * according to the results of the monte carlo simulations.  Measures difference
   * between monte carlo mean and actual results on a scale from 0 to 5.
   */
  const SEATS_GAINED = {
    name: 'Seats Gained',
    colorize: (id) => {
      let results = this.state.data
        && this.state.data[this.state.year]
        && this.state.data[this.state.year][id];

      if (!results || results.ndists < MIN_DISTS)
        return DEFAULT_COLOR;
      if (results.test3.n_matches < MIN_MATCHES || results.test3.p > THRESHOLD)
        return '#bbb';

      const gained = Math.abs(results.test3.mean_seats - results.dseats) / 4;

      return pick(gained, ELECTRIC);
    },
    colors: ELECTRIC,
    labels: ELECTRIC.map((_,i) => (4 * i / ELECTRIC.length).toFixed(1)),
    discrete: false,
    desc: 'Number of seats gained by gerrymandering'
  };


  // Indexed by display state: the avaialable colorizers for each
  return {
    congress: {
      up: [FAILED_CONGRESS, SEATS_GAINED, SEATS_WON, PARTISANSHIP_STATEWIDE],
      down: [PARTISANSHIP_DISTRICT]
    },
    states: {
      up: [FAILED_STATES, PARTISANSHIP_STATEWIDE, SEATS_WON],
      down: [PARTISANSHIP_AVERAGE]
    }
  };
}
