import React from 'react';

import {
  MIN_DISTS, THRESHOLD,
  validateTTest, validateMeanMedian, validateMonteCarlo
} from '../common/tests.js';

// Test validators in a single array, for iterating through tests
const validate = [
  ({seats, ndists}) => validateTTest(seats, ndists - seats),
  ({test2}) => validateMeanMedian(test2),
  ({test3}) => validateMonteCarlo(test3)
];

const testnames = ['Lopsided Wins', 'Consistent Advantage', 'Simulated Elections'];

const TestTooltip = ({results, name, year}) => {
  // Reasons for not running tests
  if ((!results) || (results.multimember)) {
    return (
      <em>We do not have results for <strong>{name}</strong> in this cycle, either because it has not yet held elections this cycle, or because it uses multimember districts, which are not compatible with our tests.</em>
    )
  } else if (results.ndists < MIN_DISTS) {
    return (
      <em><strong>{name}</strong> did not have enough districts to run our tests.</em>
    )
  }

  // Format test results
  const testResults = [1,2,3].map((i) => results[`test${i}`] &&
    <div className="tooltip-line" key={i}>
      <span className="tooltip-test">{testnames[i-1]}: </span>
      <span className="tooltip-result">
        {!validate[i-1](results)
          ? <span className="test-skip">Skipped</span>
          : results[`test${i}`].p < THRESHOLD
            ? <span className="test-fail">Failed</span>
            : <span className="test-pass">Passed</span>
          }

      </span>
    </div>
  );

  // Calculate number of seats gained from monte-carlo results
  let seatsGained = results.test3
    && validateMonteCarlo(results.test3)
    && results.test3.p < THRESHOLD
    && `${Math.abs(results.test3.mean_seats - results.dseats).toFixed(1)}
      ${results.test3.favor > 0 ? 'D' : 'R'}`;

  return (
    <div>
      <div className="tooltip-line">
        <span className="tooltip-state">{name}</span>
        {/* <span className="tooltip-year"> {year}</span>*/}
      </div>
      <div className="tooltip-results">{testResults}</div>
      {seatsGained &&
        <div className="tooltip-line tooltip-gained">
          <span className="tooltip-result">{seatsGained}</span> Seats gained:
        </div>
      }
    </div>
  );
}

const ReformsToolTip = ({name, type}) => {
  let info = type ? type : "No upcoming reforms";
  return (
    <div>
      <div className='tooltip-state'>{name}</div>
      <div className='tooltip-line'>{info}</div>
    </div>
  );
}

export { ReformsToolTip };
export default TestTooltip;
