import React from 'react';

import { formatP, oneInP, formatPercent } from '../common/util.js';

import {efficiencyGap, THRESHOLD, validateTTest, validateMeanMedian, validateMonteCarlo} from '../common/tests.js';

// const validate = [
//   ({seats, ndists}) => validateTTest(seats, ndists - seats), 
//   ({test2}) => validateMeanMedian(test2), 
//   ({test3}) => validateMonteCarlo(test3)
// ];

export const TTestText = ({p, year, statename, dmean, rmean, seats, ndists}) => {
	let results;
	if (validateTTest(seats, ndists - seats)) {
		results = (
			<p className="results-desc">
				In <b>{statename}</b>{"'"}s <b>{year}</b> election, Republicans won their districts with an average of <b>{formatPercent(rmean)}</b> of the vote, and
				Democrats won their districts with an average of <b>{formatPercent(dmean)} </b>
				of the vote.&#8194;

				{(p < THRESHOLD) ? (
          <span className="conditional fail">
						The difference between the two parties win margins provides evidence that <b>{statename}</b> may be gerrymandered to gain an advantage for {(dmean < rmean) ? 'Democrats' : 'Republicans'}. The chance that this difference would have arisen by nonpartisan processes alone is <b>{formatP(p)}</b><b>{oneInP(p)}</b>.
					</span>
				) : (
          <span className="conditional pass">
						The difference between the two parties{"'"} win margins does not provide evidence for the case that <b>{statename}</b> is gerrymandered.
					</span>
          
				)}
			</p>
		);
	} else {
		results = (
			<p className="results-desc">
				Unfortunately, the two-sample t-test requires that both parties win at least two seats, and {(seats < 2) ? 'Democrats' : 'Republicans'} won <b>{(seats < 2) ? seats : ndists-seats}</b> in <b>{statename}</b> in <b>{year}</b>.
			</p>
		);
	}

	return (
		<div className="results-text">
			<p className="test-desc">
				The lopsided wins test compares the win margins of the two parties.
				In states that are gerrymandered, the party that benefits from the gerrymander
				will win many seats by small margins, while the opposing party wins a few seats
				by overwhelming margins. The differences in win margins can be calculated using 
				the <a href="http://www.itl.nist.gov/div898/handbook/eda/section3/eda353.htm" target="_blank" rel="noopener noreferrer">two-sample t-test</a>.
			</p>
			{results}
		</div>
	);
};


export const MeanMedianText = ({p, med, mean, voteshare, statename, year}) => {
	let diff = mean - med;
  let results;
  if (validateMeanMedian({mean})) {
    results = (
  		<p className="results-desc">
  			In <b>{statename}</b> in <b>{year}</b>, the median Democratic vote share was <b>{formatPercent(med)}</b>, the average Democratic vote share was
  			<b> {formatPercent(mean)}</b>, and the difference was <b>{formatPercent(diff)}</b>.&#8194;
  			{(p < THRESHOLD) ? (
  				<span className="conditional fail">
  					This difference provides evidence that <b>{statename}</b> may be
  					gerrymandered to gain an advantage for {(diff < 0 ) ? 'Democrats' : 'Republicans'}.
  					The chance that this difference would have arisen by nonpartisan processes alone is <b>{formatP(p)}{oneInP(p)}</b>.
  				</span>
  			) : (
  				<span className="conditional pass">
  					This difference does not provide evidence for the case 
  					that <b>{statename}</b> is gerrymandered.
  				</span>
  			)}
  		</p>
    )
    } else {
      results = (
  			<p className="conditional">
  				In heavily partisan states such as <b>{statename}</b> in <b>{year}</b>, the mean-median difference is not a reliable measure of gerrymandering, so we skip it.
  			</p>
  		)
    }
  
  
	return (
		<div className="results-text">
			<p className="test-desc">
				The mean-median difference compares the median Democratic vote share across all
				districts to the average (arithmetic mean) Democratic vote share. If the median is larger than the average,
				the state may be a Democratic gerrymander. If the median is smaller than the
				average, the state may be a Republican gerrymander.
			</p>
      {results}
		</div>
	);
};

export const MonteCarloText = ({n_matches, voteshare, seats, mean_seats, p, ndists, statename, year}) => {
  let results;
  if (validateMonteCarlo({n_matches})) {
    results = (
      <p className="results-desc">
      In <b>{statename}</b> in <b>{year}</b>, Democrats won <b>{seats}</b> out of <b>{ndists}</b> seats with <b>{formatPercent(voteshare)}</b> of the statewide
      vote. Our simulations indicate that, according to nationwide results in <b>{year}</b>, Democrats would be expected to win <b>{(mean_seats).toFixed(1)}</b> seats. The difference of <b>{(mean_seats - seats).toFixed(1)}</b> seats
        {(p < THRESHOLD) ? (
          <span> provides evidence that <b>{statename}</b> may have been gerrymandered to gain an advantage for {(mean_seats < seats) ? 'Democrats' : 'Republicans'}.</span>
        ) : (
         <span> does not provide evidence that <b>{statename}</b> was gerrymandered.</span>)}
        </p>
      )
    } else {
      results = (
        <p className="results-desc">
        Unfortunately, we could not simulate results from <b>{statename}</b>&#8194;in <b>{year}</b>. This can occur because the state has a large number of Congressional seats or because the state has particularly complicated political
        geography.
        </p>
      )
    }
      
	return (
		<div className="results-text">
			<p className="test-desc">
				By comparing elections in one state in one year to all Congressional elections carried out in that year, we can estimate the number of seats Democrats and Republicans might be expected to win. A large deviation from this expected value is not itself evidence of partisan gerrymandering because differences could be caused by any number of factors, but it can be combined with the lopsided-wins test and the mean-median difference to estimate the effects of a possible partisan gerrymander.&#8194;
      </p>
      {results}
      
		</div>
	);
}
