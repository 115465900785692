import React from 'react';
import axios from 'axios';
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps';
import {resize} from '../common/util.js';
import {
  throttle,
  // debounce
} from 'throttle-debounce';

// TODO: lazy load this
import USA from '../data/states.json';

// Magic constants to fit maps inside the canvas
const ZOOM_MAGIC = 20;
const WIDTH_MAGIC = 800;
const HEIGHT_MAGIC = 475;

/**
 * Position tooltip by hand
 */
function pin(event, target) {
  const margin = 10;
  const rect = target.parentElement.getBoundingClientRect();
  const offsetX = event.clientX - rect.left;
  const offsetY = event.clientY - rect.top;

  target.style.left = `${offsetX - target.offsetWidth / 2}px`;
  target.style.top = `${offsetY - target.offsetHeight - margin}px`;
}

/**
 * Position reform tooltip
 */
function pinReform(event, target) {
  // const margin = 0;
  const rect = target.parentElement.getBoundingClientRect();

  target.style.left = `${event.clientX - rect.left + 20}px`;
  target.style.top = `${event.clientY - rect.top + 25}px`
}

/**
 *
 */
class USAMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null,
      hover: null,
      optimize: false
    };

    // Debounce the window-bound resize function
    this.resize = throttle(100, resize.bind(this));
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    // this.div.removeEventListener('mousemove', pin);
  }

  componentWillReceiveProps() {
    this.resize();
  }

  // this was previously here with a typo (comOponent) but when the typo was fixed, it breaks the map. so commenting it out. 9/21/18 WTA
  // componentDidUpdate() {
  //   this.setState({optimize: true});
  // }

  render() {
    const {colorize, onClick, tooltipFor} = this.props;
    const {width, height, tip, optimize} = this.state;

    // Scale to fit entire map
    const zoom = Math.min(width / WIDTH_MAGIC, height / HEIGHT_MAGIC);
    let isMobile;
    if (typeof window !== `undefined`) {
      isMobile = window.matchMedia('(max-width: 550px)').matches;
    }

    return (
      <div role="layout" className="svg-wrapper" onMouseLeave={() => this.setState({tip: null})}
        ref={div => this.div = div}>
        <div id="tooltip-usa" className="tooltip" ref={div => this.tooltip = div}
          style={{display: tip ? 'block' : 'none'}}>
          {tip}
        </div>
        {width ? (
          <ChoroplethMap
            key="drillup"
            noOpt={!optimize}
            zoom={zoom}
            width={width}
            height={height}
            geography={USA}
            onClick={onClick}
            target={this.div}
            setHover={!isMobile ? (geo, i, event) => {
              geo && throttle(15, pin(event, this.tooltip));
              this.setState({tip: tooltipFor(geo && geo.properties.id)})
            } : undefined}
            colorize={(geo) => colorize(geo.properties.id)}
            />
          ) : null
        }
      </div>
    );
  }
}

/**
 *
 */
class DrilldownMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      geo: null,
      file: null,
      height: null,
      width: null,
      tip: null,
      optimize: false
    };

    this.resize = resize.bind(this);
  }

  /**
   * Load the map before passing to ChoroplethMap so we have
   * direct access to the topojson before it renders
   */
  componentDidMount() {
    this.doUpdate(this.props.file);
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    // this.div.removeEventListener('mousemove', pin);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.file && (nextProps.file !== this.props.file)) {
      this.doUpdate(nextProps.file);
    } else if (nextProps.geo) {
      this.setState({geo: nextProps.geo});
    }
  }

  doUpdate(file) {
    // Set the geography
    if (file !== this.state.file) {
      axios.get(`/assets/maps/districts/${file}`)
        .then((response) => this.setState({geo: response.data, file: file}));
    }

    this.resize();
  }

  // this was previously here with a typo (comOponent) but when the typo was fixed, it breaks the map. so commenting it out. 9/21/18 WTA

  // componentDidUpdate(prevProps) {
  //   if (this.props != prevProps) {
  //     this.setState({optimize: true});
  //   }
  // }

  render() {
    const {
      colorize,
      // animate,
      tooltipFor
    } = this.props;
    let {geo, width, height, optimize, tip} = this.state

    // Get the translation for this state
    let center, zoom;
    if (geo && geo.bbox) {
      const [minX, minY, maxX, maxY] = geo.bbox;

      // Calculate new center point
      center = [(minX + maxX)/2, (minY + maxY)/2];
      // Calculate zoom to fit entire map
      zoom = Math.min(width / (maxX - minX), height / (maxY - minY)) / ZOOM_MAGIC;
    }

    return (
      <div role="layout" className="svg-wrapper" onMouseLeave={() => this.setState({tip: null})}
        ref={div => this.div = div}>
        <div id="tooltip-drilldown" className="tooltip" ref={div => this.tooltip = div}
          style={{display: tip ? 'block' : 'none'}}>
          {tip}
        </div>
        <button id="button-drillup" className="button" onClick={this.props.drillup}><i className="fas fa-angle-left"></i> All states</button>
        {geo ? (
          <ChoroplethMap
            cacheKey={this.state.file}
            noOpt={!optimize}
            zoom={zoom}
            width={width}
            height={height}
            center={center}
            geography={geo}
            tooltip={this.tooltip}
            target={this.target}
            setHover={(geo, i, event) => {
              geo && throttle(15, pin(event, this.tooltip));
              this.setState({tip: tooltipFor(geo && i)});
            }}
            colorize={(geo, i) => colorize(i)}
            />
          ) : null
        }
      </div>
    );
  }
}

class ReformsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null,
      hover: null,
      optimize: false
    };

    // Debounce the window-bound resize function
    this.resize = throttle(100, resize.bind(this));
    // this.resize = resize.bind(this);
  }

  componentDidMount() {
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  componentWillReceiveProps() {
    this.resize();
  }

  render() {
    const {colorize, onClick, tooltipFor} = this.props;
    const {width, height, tip, optimize} = this.state;

    // Scale to fit entire map
    const zoom = Math.min(width / WIDTH_MAGIC, height / HEIGHT_MAGIC);
    let isMobile;
    if (typeof window !== `undefined`) {
      isMobile = window.matchMedia('(max-width: 550px)').matches;
    }

    return (
      <div 
        role="layout"
        className="svg-wrapper"
        onMouseLeave={() => this.setState({tip: null})}
        ref={div => this.div = div}>
        <div id="tooltip-usa"
          className="tooltip"
          ref={div => this.tooltip = div}
          style={{display: tip ? 'block' : 'none'}}>
          {tip}
        </div>
        {width ? (
          <ChoroplethMap
            key='reforms'
            noOpt={!optimize}
            zoom={zoom}
            width={width}
            height={height}
            geography={USA}
            onClick={onClick}
            target={this.div}
            setHover={!isMobile ? (geo, i, event) => {
              geo && throttle(15, pinReform(event, this.tooltip));
              this.setState({tip: tooltipFor(geo.properties.id)})
            } : undefined}
            colorize={(geo) => colorize(geo)} />
          ) : null
        }
      </div>
    );
  }
}

/**
 * The Wrapper component for a US map with react-simple-maps
 * Most behavior is specified, except for the exact geography,
 * the colorize function, and seveveral optional transformations
 * and event handlers
 */
const ChoroplethMap = (props) => {
  const {
    geography,
    colorize,
    cacheKey='',
    target=null,
    zoom=1,
    center=[0,0],
    setHover=() => null,
    onClick=() => null,
    noOpt=true,
    width=800,
    height=450
  } = props;

  return (
    <ComposableMap
      projection="geoAlbersUsa"
      projectionConfig={{scale: 1000*zoom}}
      width={width}
      height={height}
    >
      <ZoomableGroup
        filterZoomEvent={({ constructor: { name } }) => {
          return;
        }}
        center={center}
        >
        <Geographies
          geography={geography}
          disableOptimization={noOpt}
        >
          {(geos) => geos.geographies.map((geo, i) => {
            const color = colorize(geo, i);
            return <Geography
            cacheId={`${cacheKey}-${center}-${width}-${height}-${geo.properties.id}-${i}`}
              key={geo.rsmKey}
              geography={geo}
              onClick={() => onClick(geo)}
              projection={geos.projection}
              // onMouseMove={(_, event) => {
              //   setHover(geo, i, event);
              // }}
              style={{
                default: {
                  fill: color,
                  stroke: "#999",
                  strokeWidth: 1,
                  outline: "none",
                  cursor: onClick ? 'pointer' : 'normal',
                },
                hover: {
                  fill: color,
                  opacity: 0.75,
                  stroke: "#999",
                  strokeWidth: 1,
                  outline: "none",
                  cursor: onClick ? 'pointer' : 'normal',
                },
                pressed: {
                  fill: color,
                  outline: "none",
                  stroke: '#000',
                  opacity: 0.25,
                  strokeWidth: 1,
                },
              }}
            />;
          })}
        </Geographies>
      </ZoomableGroup>
    </ComposableMap>
  );
}

export {
  USAMap,
  DrilldownMap,
  ReformsMap,
}