/**
 * Helper functions for use across the site
 */

/**
 *
 */
export function formatPercent(result) {
    if (result > 1) return result.toFixed(1) + '%';
    if (result < 0.01) return (100*result).toFixed(2) + '%';
    return (100*result).toFixed(1) + '%';
}

/**
 *
 */
export function formatP(pvalue) {
    if (pvalue > 0.01) {
        return pvalue.toFixed(3);
    } else return pvalue.toFixed(4);
}

/**
 *
 */
export function oneInP(pvalue) {
    if (pvalue < 0.05) {
        var inP = (1/pvalue).toFixed(0);
        return ` (or 1 in ${inP})`;
    } else return '';
}

/**
 * Replace brace-wrapped variables in a template string with the values
 * corresponding values of the members of context
 * @param {String} templateString
 * @param {Object} context
 * @returns {String} the rendered version of the string
 */
export function replace(templateString, context) {
    let matches = templateString.match(/{\w+}/g);
    let outputString = templateString;

    for (let m of matches) {
        // Remove the brackets
        let key = m.slice(1,-1);

        if (key in context) {
            outputString = outputString.replace(m, context[key]);
        }
    }

    return outputString;
}

/**
 * Clamp a value to be within the provided min and max
 * @param {Number} val -- the value to be clamped
 * @param {Number} min -- minimum acceptable value
 * @param {Number} max -- maximum acceptable value
 */
export function clamp(val, min, max) {
    return (val < min) ? min : (val > max) ? max : val;
}

/**
 * Utility function for resizing based on a child div
 */
export function resize() {
  const RESIZE_THRESH = 5; // pixels of change needed to trigger resize
  this.setState({optimize: false});

  // Set the box height
  if (!this.state.height || Math.abs(this.state.height - this.div.clientHeight) > RESIZE_THRESH)
    this.setState({height: this.div.clientHeight});
  if (!this.state.width || Math.abs(this.state.width - this.div.clientWidth) > RESIZE_THRESH)
    this.setState({width: this.div.clientWidth});
}
