import React from 'react';
import {
  VictoryChart, VictoryAxis, VictoryBar,
  VictoryLine, VictoryScatter, VictoryTooltip,
} from 'victory';

// import NeedleChart from './NeedleChart.jsx';
import {
  THRESHOLD,
  MIN_MATCHES,
  validateMeanMedian,
  // MIN_DISTS,
  // MIN_VOTESHARE,
  // validateTTest,
  // validateMonteCarlo
} from '../common/tests.js';
import { Colors } from '../common/colorize.js';



import './charts.css';

/**
 * Utility function for jittering points on a graph
 * @param {Number} point   -- the center point of the jitter
 * @param {Number} [width] -- the range around which to jitter
 */
function jitter(point, width=0.1) {
    return point + (width * (Math.random() - 0.5));
}

function copyToClipboard(element) {
  var el = document.querySelector(element);
  var textToCopy = el.innerText;
  var myTemporaryInputElement = document.createElement("input");
  myTemporaryInputElement.type = "text";
  myTemporaryInputElement.value = textToCopy;
  
  document.body.appendChild(myTemporaryInputElement);
  
  myTemporaryInputElement.select();
  document.execCommand("copy");
  
  document.body.removeChild(myTemporaryInputElement);
 
  // var $temp = document.createElement("input");
  // document.getElementsByTagName("body")[0].append($temp);
  // $temp.val(document.getElementById(element).text()).select();
  // document.execCommand("copy");
  // $temp.remove();
}


/**
 * Plot the results of all 3 tests together
 */
const ChartGroup = ({test1, test2, test3, results, dseats, voteshare}) => {
  return (
    <div className="chart-group">
      <TTestChart {...test1} results={results} />
      <MeanMedianChart {...test2} results={results} voteshare={voteshare} />
      {test3 &&
        <MonteCarloChart {...test3} results={results} voteshare={voteshare} seats={dseats}/>
        /* <NeedleChart {...test3} results={results} voteshare={voteshare} seats={dseats} /> */
      }
      <div id="resultstocopy">{results.join(['\t'])}</div>
      <div id="getdata">
      <button className="button" id="copyresults-button" onClick={() => copyToClipboard('#resultstocopy')}>Copy vote shares to clipboard</button>
      <p className="footnote">For all data, see <a href="/resources">Resources</a>.</p>
      </div>
    </div>
  );
};

/**
 * Really ugly mess to format the p-values
 */
export const PValue = ({p, favor, skip}) => {
  const favoring = favor < 0 ? 'Republicans' : 'Democrats';
  const fail = (0 < p) && (p < THRESHOLD);
  const skipped = (p < 0) || skip;
  const color = (!skipped && fail) ? Colors.FAIL : Colors.GREY;
  p = (p < 0.001) ? '< 0.001' : p.toFixed(3)

  return (
    <span className="chart-pvalue" style={{color}}>
    {skipped ? 'skipped' : (
      <span>
        <em>p</em>-value: {p}{(fail ? ` (favoring ${favoring})` : '')}
      </span>
    )}
    </span>
  );
}

export class TTestChart extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.results === nextProps.results)
      ? false
      : true;
  }

  render() {
    const {p, favor, dmean, rmean, results} = this.props;
    // Split out the results into Democratic and Republican wins
    let rwins = [], dwins = [];

    results.map((y, i) => {
      if (1 < y || y < 0) return;
      if (y > 0.5)
        dwins.push({
          x: jitter(0.25),
          y,
          label: `${(100*y).toFixed(1)}% Democratic`
        });
      else
        rwins.push({
          x: jitter(0.75),
          y: 1 - y,
          label: `${(100*(1-y)).toFixed(1)}% Republican`
        });
    });

    return (
      <div id="chart-t-test" className="chart">
        <h4 className="chart-title">
          Lopsided Wins
        </h4>
        <PValue p={p} favor={favor}/>
        <VictoryChart height={175} domainPadding={[0,0]}>
          <VictoryAxis
            tickValues={[0.25, 0.75]}
            tickFormat={['Democratic', 'Republican']}
            style={{
              ticks: {size: 5, stroke: "black"},
              axisLabel: {padding: 25, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"},
              tickLabels: {padding: 3, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"}
            }}/>
          <VictoryAxis dependentAxis
            style={{
              ticks: {size: 5, stroke: "black"},
              axis: {strokeWidth: 1},
              axisLabel: {padding: 35, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"},
              tickLabels: {fontSize: 12, padding: 3, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"}
            }}
            tickFormat={['50%', '75%', '100%']}
            tickValues={[0.5, 0.75, 1.0]}
            label="Vote share"/>
            <VictoryScatter
              domain={{x: [0, 1], y: [0.5, 1.0]}}
              data={dwins}
              size={5}
              labelComponent={<VictoryTooltip/>}
              style={{ data: { fill: Colors.DEM, opacity: 0.5} }}/>
            <VictoryScatter
              domain={{x: [0, 1], y: [0.5, 1.0]}}
              data={rwins}
              size={5}
              labelComponent={<VictoryTooltip/>}
              style={{ data: { fill: Colors.REP, opacity: 0.5 } }}/>
            {(dmean > 0) ? (
              <VictoryLine
                data={[{x: 0, y: dmean}, {x: 0.5, y: dmean}]}
                style={{ data: { stroke: Colors.DEM } }}/>
              ) : null
            }
            {(rmean > 0) ? (
              <VictoryLine
                data={[{x: 0.5, y: rmean}, {x: 1, y: rmean}]}
                style={{ data: { stroke: Colors.REP } }}/>
              ) : null
            }
        </VictoryChart>
      </div>
    );
  }
}

export class MeanMedianChart extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (this.props.results === nextProps.results)
      ? false
      : true;
  }

  render() {
    let {p, favor, mean, med, results, voteshare} = this.props;
    let all = results.map((x, i) => ({y: jitter(0.5), x, district: i + 1}));
    let skip = !validateMeanMedian({mean});


    return (
      <div id="chart-mean-median" className="chart">
        <h4 className="chart-title">
          Consistent Advantage
        </h4>
        <PValue p={p} favor={favor} skip={skip}/>
        {(skip) ? (
          <div className="chart-skipped">In heavily partisan states, the mean-median difference
            is not a reliable measure of gerrymandering. Instead, a chi-square test for variance
            is more sensitive. This is discussed
            further <a href='https://web.math.princeton.edu/~sswang/wang16_ElectionLawJournal_gerrymandering-MD-WI_.pdf'>here</a> as
            it relates to Maryland's <i>Shapiro v. McManus</i>.
          </div>
        ) : (
        <VictoryChart height={150}
          domain={{x: [0, 1], y: [0.4, 0.6]}}
          domainPadding={{x: [50, 0]}}>

          {/*<VictoryLabel text={`difference: ${(100*(mean-med)).toFixed(1)}%`}
            x={0} y={40} style={{fontStyle: 'italic', fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"}}/>*/}
          <VictoryAxis
            label="Democratic vote share"
            domain={[0,1]}
            style={{
              ticks: {size: 5, stroke: "black"},
              axis: {strokeWidth: 1},
              axisLabel: {padding: 35, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"},
              tickLabels: {padding: 10, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"}
            }}
            tickValues={[0, 0.25, 0.5, 0.75, 1]}
            tickFormat={(d) => 100*d + '%'}/>
          <VictoryScatter
            data={all}
            size={5}
            style={{data: {fill: Colors.NEUTRAL, opacity: 0.5}}}/>
          <VictoryLine
            labels={['mean', '']}
            data={[{x: mean, y: 0.3}, {x: mean, y:0.6}]}
            style={{data: {stroke: Colors.GREY}, labels: {fill: Colors.GREY, fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"}}}/>
          <VictoryLine
            labels={['', 'median']}
            data={[{x: med, y: 0.4}, {x: med, y: 0.6}]}
            style={{labels: {fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif"}}}
            />
        </VictoryChart>
      )}
      </div>
    );
  };
}

export const MonteCarloChart = ({p, favor, seat_hist, seats, n_matches}) => {
  let ticks = [];
  let seat_sims = [];
  let districts = 0;
  if (!seat_hist || (n_matches < MIN_MATCHES)) p = -1;
  else {
    let seat_array = Object.values(seat_hist);
    districts = seat_array.length;

    // // Fill in ticks array with valid indices
    // ticks = seat_array.map(
    //   (n, i) => n/n_matches > 0 && i
    // ).reduce(
    //   // Remove zeros from the list
    //   (acc, curr) => curr !== false ? [...acc, curr] : acc,
    // []);
    //
    // // Fill in seat_hist with number of sims for each index
    // seat_hist = seat_array.map(
    //   n => n/n_matches > 0 && n
    // );

    for (var i = 0; i < seat_array.length; i++) {
      let sims = seat_array[i];
      seat_sims.push(sims);
      if (sims/n_matches > 0) ticks.push(i);
    }
  }

  return (
    <div id="chart-monte-carlo" className="chart">
      <h4 className="chart-title">
        Simulated Elections
      </h4>
      <PValue p={p} favor={favor}/>
      {(p === -1) ? (
        <div className="chart-skipped">Simulations could not be run on this state.
        </div>
      ) : (
        <VictoryChart
        height={200}
        domain={{y: [ticks[0]-0.75, ticks[ticks.length - 1]+0.5]}}
        >
          <VictoryBar horizontal
            data={seat_sims}
            barRatio={(3 - ((20 + (ticks.length))/(1.8*districts)))}
            labels={(d) => `${d.x} Seats (${(100*d.y/n_matches).toFixed(1)}% of simulations)`}
            labelComponent={<VictoryTooltip orientation="top" dx={-10}/>}
            style={{
              data: {
                fill: (d) => d.x === seats ? Colors.HIGHLIGHT : Colors.NEUTRAL,
                cursor: 'pointer'
              }
            }}/>
          <VictoryAxis
            tickFormat={(t) => `${t/1000}k`}
            label="Number of simulations with given result"
            offsetY={50}
            style={{
              ticks: {size: 5, stroke: "black"},
              axisLabel: {fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", padding: 25},
              tickLabels: {fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", padding: 3}
            }}
            />
          <VictoryAxis dependentAxis
            tickValues={ticks}
            style={{
              ticks: {size: 5, stroke: "black"},
              axisLabel: {fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
              padding: 25},
              tickLabels: {fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", padding: 3}}}
            label="Democratic seats won" />
        </VictoryChart>
      )}
    </div>
  );
};

export default ChartGroup;
